import { Field, FieldGroup, Spinner } from "@app/design-system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { MapImage } from "../utils/loadImage";

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.footnote}
`;

const Styled2ColGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 8px;
`;

const Styled4ColGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto minmax(0, 1fr);
  align-items: center;
  gap: 8px;
`;

const optusSymbologyNames = [
  "OptusMobileSites",
  "Optus5GMobileCoverageOutdoor",
  "Optus5GMobileCoverageExternal",
  "Optus4GMobileCoverageOutdoor",
  "Optus4GMobileCoverageExternal",
  "Optus3GMobileCoverageOutdoor",
  "Optus3GMobileCoverageExternal",
] as const;

const telstraSymbologyNames = [
  "TelstraMobileSites",
  "Telstra5GMobileCoverageOutdoor",
  "Telstra5GMobileCoverageExternal",
  "Telstra4GMobileCoverageOutdoor",
  "Telstra4GMobileCoverageExternal",
  "Telstra3GMobileCoverageOutdoor",
  "Telstra3GMobileCoverageExternal",
] as const;

const tpgSymbologyNames = [
  "TPGMobileSites",
  "TPG5GMobileCoverageOutdoor",
  "TPG5GMobileCoverageExternal",
  "TPG4GMobileCoverageOutdoor",
  "TPG4GMobileCoverageExternal",
  "TPG3GMobileCoverageOutdoor",
  "TPG3GMobileCoverageExternal",
] as const;

type OptusSymbologyName = (typeof optusSymbologyNames)[number];
type TelstraSymbologyName = (typeof telstraSymbologyNames)[number];
type TpgSymbologyName = (typeof tpgSymbologyNames)[number];

const optusSymbologyLabels = {
  OptusMobileSites: "Optus sites",
  Optus5GMobileCoverageOutdoor: "Optus 5g outdoor",
  Optus5GMobileCoverageExternal: "Optus 5g external",
  Optus4GMobileCoverageOutdoor: "Optus 4g outdoor",
  Optus4GMobileCoverageExternal: "Optus 4g external",
  Optus3GMobileCoverageOutdoor: "Optus 3g outdoor",
  Optus3GMobileCoverageExternal: "Optus 3g external",
} as const satisfies Record<OptusSymbologyName, string>;

const telstraSymbologyLabels = {
  TelstraMobileSites: "Telstra sites",
  Telstra5GMobileCoverageOutdoor: "Telstra 5g outdoor",
  Telstra5GMobileCoverageExternal: "Telstra 5g external",
  Telstra4GMobileCoverageOutdoor: "Telstra 4g outdoor",
  Telstra4GMobileCoverageExternal: "Telstra 4g external",
  Telstra3GMobileCoverageOutdoor: "Telstra 3g outdoor",
  Telstra3GMobileCoverageExternal: "Telstra 3g external",
} as const satisfies Record<TelstraSymbologyName, string>;

const tpgSymbologyLabels = {
  TPGMobileSites: "TPG sites",
  TPG5GMobileCoverageOutdoor: "TPG 5g outdoor",
  TPG5GMobileCoverageExternal: "TPG 5g external",
  TPG4GMobileCoverageOutdoor: "TPG 4g outdoor",
  TPG4GMobileCoverageExternal: "TPG 4g external",
  TPG3GMobileCoverageOutdoor: "TPG 3g outdoor",
  TPG3GMobileCoverageExternal: "TPG 3g external",
} as const satisfies Record<TpgSymbologyName, string>;

interface MobileCoverageFeatureServerIconJson {
  layers: {
    layerId: number;
    layerName: string;
    layerType: "Feature Layer";
    minScale: number;
    maxScale: number;
    // NOTE: The legend is an array of one for all current items, and we'd need different handling to support more if that changed
    legend: [
      {
        label: string;
        url: string;
        imageData: string;
        contentType: string;
        height: number;
        width: number;
      },
    ];
  }[];
}

interface LoadSymbologyParams {
  accessToken: string | undefined;
  signal: AbortSignal;
}

const loadSymbologyList = (params: LoadSymbologyParams) => {
  const symbologyUrl = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/AthenaMobileInfrastructure/MapServer/legend?f=pjson`;

  return fetch(symbologyUrl, {
    headers: symbologyUrl.includes("shared-athena")
      ? { Authorization: `Bearer ${params.accessToken}` }
      : undefined,
    signal: params.signal,
  })
    .then((response) => {
      params.signal.throwIfAborted();
      return response.json() as Promise<MobileCoverageFeatureServerIconJson>;
    })
    .then((data) => {
      params.signal.throwIfAborted();
      return Promise.all(
        data.layers.map((layer) => ({
          src: `data:${layer.legend[0].contentType};base64,${layer.legend[0].imageData}`,
          imageId: layer.layerName,
        })),
      );
    })
    .catch(catchAbortError);
};

const MobileCoverageLegend = () => {
  const accessToken = useAuthAccessToken();
  const [symbology, setSymbology] = useState<MapImage[]>();

  useEffect(() => {
    const controller = new AbortController();

    void loadSymbologyList({ accessToken, signal: controller.signal }).then(
      (loadedSymbology) => loadedSymbology && setSymbology(loadedSymbology),
    );

    return () => controller.abort();
  }, [accessToken]);

  const optusSiteName: OptusSymbologyName = "OptusMobileSites";
  const telstraSiteName: TelstraSymbologyName = "TelstraMobileSites";
  const tpgSiteName: TpgSymbologyName = "TPGMobileSites";

  const optusSiteMapImage = symbology?.find(
    ({ imageId }) => imageId === optusSiteName,
  );
  const telstraSiteMapImage = symbology?.find(
    ({ imageId }) => imageId === telstraSiteName,
  );
  const tpgSiteMapImage = symbology?.find(
    ({ imageId }) => imageId === tpgSiteName,
  );

  return (
    <FieldGroup>
      <Field label="Optus">
        {symbology ? (
          <>
            {/* Show the sites separately for nicer alignment */}
            {optusSiteMapImage && (
              <Styled2ColGrid>
                <StyledImage src={optusSiteMapImage.src} alt="" aria-hidden />
                <StyledText>{optusSymbologyLabels[optusSiteName]}</StyledText>
              </Styled2ColGrid>
            )}

            <Styled4ColGrid>
              {optusSymbologyNames.map((name) => {
                const mapImage = symbology.find(
                  ({ imageId }) =>
                    imageId === name && imageId !== optusSiteName,
                );

                return mapImage ? (
                  <React.Fragment key={name}>
                    <StyledImage src={mapImage.src} alt="" aria-hidden />
                    <StyledText>{optusSymbologyLabels[name]}</StyledText>
                  </React.Fragment>
                ) : null;
              })}
            </Styled4ColGrid>
          </>
        ) : (
          <Spinner />
        )}
      </Field>

      <Field label="Telstra">
        {symbology ? (
          <>
            {/* Show the sites separately for nicer alignment */}
            {telstraSiteMapImage && (
              <Styled2ColGrid>
                <StyledImage src={telstraSiteMapImage.src} alt="" aria-hidden />
                <StyledText>
                  {telstraSymbologyLabels[telstraSiteName]}
                </StyledText>
              </Styled2ColGrid>
            )}

            <Styled4ColGrid>
              {telstraSymbologyNames.map((name) => {
                const mapImage = symbology.find(
                  ({ imageId }) =>
                    imageId === name && imageId !== telstraSiteName,
                );

                return mapImage ? (
                  <React.Fragment key={name}>
                    <StyledImage src={mapImage.src} alt="" aria-hidden />
                    <StyledText>{telstraSymbologyLabels[name]}</StyledText>
                  </React.Fragment>
                ) : null;
              })}
            </Styled4ColGrid>
          </>
        ) : (
          <Spinner />
        )}
      </Field>

      <Field label="TPG">
        {symbology ? (
          <>
            {/* Show the sites separately for nicer alignment */}
            {tpgSiteMapImage && (
              <Styled2ColGrid>
                <StyledImage src={tpgSiteMapImage.src} alt="" aria-hidden />
                <StyledText>{tpgSymbologyLabels[tpgSiteName]}</StyledText>
              </Styled2ColGrid>
            )}

            <Styled4ColGrid>
              {tpgSymbologyNames.map((name) => {
                const mapImage = symbology.find(
                  ({ imageId }) => imageId === name && imageId !== tpgSiteName,
                );

                return mapImage ? (
                  <React.Fragment key={name}>
                    <StyledImage src={mapImage.src} alt="" aria-hidden />
                    <StyledText>{tpgSymbologyLabels[name]}</StyledText>
                  </React.Fragment>
                ) : null;
              })}
            </Styled4ColGrid>
          </>
        ) : (
          <Spinner />
        )}
      </Field>
    </FieldGroup>
  );
};

export default MobileCoverageLegend;
